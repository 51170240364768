import React, { useState, useEffect } from 'react';
import EmojiGrid from './components/EmojiGrid';
import EmojiPalette from './components/EmojiPalette';
import { emojiSequences, emojiPalette } from './components/gameData';
import Emoji from "react-emoji-render";

function App() {
  const [currentSequence, setCurrentSequence] = useState(null);
  const [playerGuess, setPlayerGuess] = useState(Array(5).fill(''));
  const [selectedCellIndex, setSelectedCellIndex] = useState(-1);
  const [cellColors, setCellColors] = useState(Array(5).fill(''));
  const [result, setResult] = useState('');
  const [guessHistory, setGuessHistory] = useState([]);

  useEffect(() => {
    startNewGame();
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleKeyPress = (event) => {
    const key = event.key;
    if (key >= '1' && key <= '5') {
      setSelectedCellIndex(parseInt(key) - 1);
    } else if (key === 'Enter') {
      checkGuess();
    }
  };

  const startNewGame = () => {
    const newSequence = emojiSequences[Math.floor(Math.random() * emojiSequences.length)];
    setCurrentSequence(newSequence);
    setPlayerGuess(Array(5).fill(''));
    setCellColors(Array(5).fill(''));
    setResult('');
  };

  const handleCellClick = (index) => {
    setSelectedCellIndex(index);
  };

  const handleEmojiClick = (emoji) => {
    if (selectedCellIndex !== -1) {
      const newGuess = [...playerGuess];
      newGuess[selectedCellIndex] = emoji;
      setPlayerGuess(newGuess);
    }
  };

  const checkGuess = () => {
    if (playerGuess.includes('')) {
      setResult('Please fill all cells before submitting!');
      return;
    }

    const newColors = playerGuess.map((emoji, index) => {
      if (emoji === currentSequence.sequence[index]) {
        return 'green';
      } else if (currentSequence.sequence.includes(emoji)) {
        return 'yellow';
      } else {
        return 'red';
      }
    });

    setCellColors(newColors);

    if (newColors.every(color => color === 'green')) {
      setResult('Congratulations! You decoded the emoji sequence!');
      setTimeout(startNewGame, 3000);
    } else {
      setResult('Not quite right. Try again!');
      setGuessHistory(prevHistory => [...prevHistory, {guess: playerGuess, colors: newColors}]);
    }
  };

  return (
    <div className="App">
      <div className="guess-history">
        {guessHistory.slice().reverse().map((historyItem, index) => (
          <EmojiGrid
            key={index}
            playerGuess={historyItem.guess}
            cellColors={historyItem.colors}
            isHistory={true}
          />
        ))}
      </div>
      <h1>Emoji Decoder</h1>
      {currentSequence && <h2><Emoji text={currentSequence.title} /></h2>}
      <div className="game-area">
        <EmojiGrid
          playerGuess={playerGuess}
          onCellClick={handleCellClick}
          cellColors={cellColors}
          selectedCellIndex={selectedCellIndex}
        />
        <EmojiPalette emojiPalette={emojiPalette} onEmojiClick={handleEmojiClick} />
        <button onClick={checkGuess}>Submit</button>
        <p>{result}</p>
      </div>
      <div className="instructions">
        <p>Use numbers 1-5 to select cells, or click on them directly.</p>
        <p>Press Enter to submit your guess.</p>
      </div>
    </div>
  );
}

export default App;