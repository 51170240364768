import React from 'react';
import Emoji from 'react-emoji-render';

const EmojiPalette = ({ emojiPalette, onEmojiClick }) => {
  return (
    <div className="emoji-palette">
      {emojiPalette.map((emoji, index) => (
        <span
          key={index}
          className="emoji-option"
          onClick={() => onEmojiClick(emoji)}
        >
          <Emoji text={emoji} />
        </span>
      ))}
    </div>
  );
};

export default EmojiPalette;
