export const emojiSequences = [
    { title: "A Day at the Beach", sequence: ["🌞","🏖️","🌊","🏄‍♂️","🍉"] },
    { title: "Cooking a Meal", sequence: ["🔪","🥕","🍳","🍽️","🍷"] },
    { title: "Going on a Road Trip", sequence: ["🚗","🗺️","⛽","🏞️","🏨"] },
    { title: "Celebrating a Birthday", sequence: ["🎂","🎁","🎈","🎉","🕯️"] },
    { title: "Working from Home", sequence: ["💻","☕","📱","🛋️","🐱"] },
    { title: "Gardening Day", sequence: ["🌱","🌷","🚿","🐝","🧤"] },
    { title: "Movie Night", sequence: ["🍿","🎬","🛋️","🥤","🕰️"] },
    { title: "Fitness Routine", sequence: ["🏋️‍♀️","🧘‍♂️","🏃‍♀️","💪","🥦"] },
    { title: "Space Exploration", sequence: ["🚀","🌎","🌟","👨‍🚀","🛰️"] },
    { title: "Baking Cookies", sequence: ["🥚","🥣","🍪","🥛","🧁"] },
    { title: "Camping Adventure", sequence: ["🏕️","🌲","🔥","🌙","🏞️"] },
    { title: "Music Festival", sequence: ["🎸","🎤","🥁","🎷","🎉"] },
    { title: "Winter Sports", sequence: ["⛷️","🏂","❄️","☕","🧣"] },
    { title: "Tropical Vacation", sequence: ["✈️","🏝️","🍹","🕶️","🐠"] },
    { title: "School Day", sequence: ["📚","✏️","🍎","🚌","📝"] },
    { title: "Pet Care", sequence: ["🐶","🦴","🐱","🐠","🦜"] },
    { title: "Grocery Shopping", sequence: ["🛒","🥕","🍎","🥖","💳"] },
    { title: "Rainy Day", sequence: ["☔","🌧️","☁️","🌈","🥾"] },
    { title: "Picnic in the Park", sequence: ["🧺","🥪","🌳","🍉","🐜"] },
    { title: "Art Studio", sequence: ["🎨","🖌️","🖼️","👨‍🎨","🖍️"] },
    { title: "Farm Life", sequence: ["🐄","🚜","🌾","🐓","🥚"] },
    { title: "Amusement Park", sequence: ["🎢","🎡","🍭","🎟️","😄"] },
    { title: "Yoga Session", sequence: ["🧘‍♀️","🧘‍♂️","🕯️","🧺","🧘"] },
    { title: "Underwater Adventure", sequence: ["🤿","🐠","🐙","🐬","🐚"] },
    { title: "Barbecue Party", sequence: ["🍖","🔥","🌽","🍺","🍔"] },
    { title: "Stargazing Night", sequence: ["🔭","🌠","🌙","🦉","🏕️"] },
    { title: "Halloween", sequence: ["🎃","👻","🦇","🍬","🕸️"] },
    { title: "Wedding Day", sequence: ["👰","🤵","💍","🎂","🥂"] },
    { title: "Spa Day", sequence: ["💆‍♀️","💅","🧖‍♀️","🫖","🕯️"] },
    { title: "Hiking Adventure", sequence: ["🥾","🏞️","🗺️","🧭","⛰️"] },
    { title: "Science Experiment", sequence: ["🧪","🔬","👨‍🔬","💥","🧠"] },
    { title: "Circus Show", sequence: ["🎪","🤹","🦁","🎭","🍿"] },
    { title: "Sushi Night", sequence: ["🍣","🥢","🍶","🍚","🐟"] },
    { title: "Car Wash", sequence: ["🚗","🧽","💦","🧼","✨"] },
    { title: "Haunted House", sequence: ["🏚️","👻","🕯️","🦇","😱"] }
];

export const emojiPalette = [...new Set(emojiSequences.map(el => el.sequence).flat())].sort(() => Math.random() - 0.5)
