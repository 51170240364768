import React from 'react';

const EmojiGrid = ({ playerGuess, onCellClick, cellColors, selectedCellIndex, isHistory = false }) => {
  return (
    <div className={`emoji-grid ${isHistory ? 'history-grid' : ''}`}>
      {playerGuess.map((emoji, index) => (
        <div
          key={index}
          className={`emoji-cell ${!isHistory && index === selectedCellIndex ? 'selected' : ''}`}
          style={{ backgroundColor: cellColors[index] }}
          onClick={() => !isHistory && onCellClick(index)}
        >
          {emoji}
        </div>
      ))}
    </div>
  );
};

export default EmojiGrid;
